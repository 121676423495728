document.querySelectorAll('.dropdown-toggle').forEach(
  dropdown => {
    dropdown.addEventListener('click', ev => {
      if (!ev.target.parentElement.classList.contains('open')) clear();
      ev.target.parentElement.classList.toggle('open');
    })
  });

document.querySelectorAll('.dropdown-toggle .caret').forEach(
  caret => {
    caret.addEventListener('click', ev => {
      if (!ev.target.parentElement.parentElement.classList.contains('open')) clear();
      ev.target.parentElement.parentElement.classList.toggle('open');
    })
  });

window.addEventListener('click', ev => {
  const isDropdownButton = ev.target.classList.contains('dropdown-toggle');
  const isDropdownCaret = ev.target.parentElement.classList.contains('dropdown-toggle') && ev.target.classList.contains('caret');
  if (!isDropdownButton && !isDropdownCaret) {
    clear();
  }
})

const clear = () => {
  document.querySelectorAll('.dropdown').forEach(dropdown => {
    if (dropdown.classList.contains('open')) {
      dropdown.classList.remove('open');
    }
  })
}

document.addEventListener('wheel', function () {
  if ('number' === document.activeElement.type) {
    document.activeElement.blur();
  }
});

import '../bootstrap.js';
